/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Stara;
  src: url("assets/fonts/Stara-Bold.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Saira";
  src: url("assets/fonts/Saira-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Saira";
  src: url("assets/fonts/Saira-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Saira";
  src: url("assets/fonts/Saira-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Stara;
}

.navbar {
  padding-bottom: 4em;
}

.bg-gradient {
  background: -moz-linear-gradient(
    14.22% -27.99% -45deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.02) 50%,
    rgba(255, 255, 255, 0.15) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.02) 50%,
    rgba(255, 255, 255, 0.15) 100%
  );
  background: -webkit-gradient(
    linear,
    14.22% -27.99%,
    85.78% 127.99%,
    color-stop(0, rgba(255, 255, 255, 0.12)),
    color-stop(0.5, rgba(255, 255, 255, 0.02)),
    color-stop(1, rgba(255, 255, 255, 0.15))
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.02) 50%,
    rgba(255, 255, 255, 0.15) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.02) 50%,
    rgba(255, 255, 255, 0.15) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.02) 50%,
    rgba(255, 255, 255, 0.15) 100%
  );
}

app-modal .content--container {
  min-width: 45vw;
  max-width: 50vw;
}

.progress--container {
  background-color: rgb(192 231 0 / 20%);
}

.progress--container,
.progress--steps {
  height: 4px;
}
